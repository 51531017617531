import React, { useEffect } from "react";

const CallUsSelector = () => {
  useEffect(() => {
    // Dynamically load the external script
    const script = document.createElement("script");
    script.src =
      "https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js";
    script.defer = true;
    script.id = "tcx-callus-js";
    script.charset = "utf-8";
    document.body.appendChild(script);

    return () => {
      // Remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <call-us-selector
      phonesystem-url="https://siptel.dt-dentalservice.de/"
      party="LiveChat378136"
    ></call-us-selector>
  );
};

export default CallUsSelector;
