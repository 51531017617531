import "./Nav.css";
import smallLogo from "../../images/s-logo.png";
import { Link } from "react-scroll";

function Nav({ menuOpen, setMenuOpen }) {
  return (
    <div className="nav">
      <div className="s-logo">
        <Link to="big-logo" spy={true} smooth={true} offset={0} duration={500}>
          <img src={smallLogo} alt="small-logo" className="dt-logo" />
        </Link>
      </div>
      <div className="res-logo">
        <Link to="header" spy={true} smooth={true} offset={-100} duration={500}>
          <img src={smallLogo} alt="small-logo" className="dt-logo" />
        </Link>
      </div>
      <div className="menu">
        <div className="menu-list">
          <ul>
            <li className="menu-item">
              <Link
                to="produkte"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                {" "}
                Produkte <span className="menu-icon"></span>
              </Link>
            </li>
            <li className="menu-item">
              <a
                href="https://webshop.dt-dentalservice.de/"
              >
                Shop <span className="menu-icon"></span>
              </a>
            </li>

            <li className="menu-item">
              <Link
                to="team"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Team <span className="menu-icon"></span>
              </Link>
            </li>
            <li className="menu-item">
              <Link
                to="downloads"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Downloads <span className="menu-icon"></span>
              </Link>
            </li>
            <li className="menu-item">
              <Link
                to="aktionen"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Aktionen <span className="menu-icon"></span>
              </Link>
            </li>
            <li className="menu-item">
              <Link
                to="kontakt"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Kontakt <span className="menu-icon"></span>
              </Link>
            </li>
          </ul>
        </div>
        <div
          className={!menuOpen ? "hamburger" : "hamburger active"}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
