import "./Header.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

function Header() {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        transition: {
          duration: 0.8,
        },
      });
    }
    if (!inView) {
      animation.start({
        opacity: 0,
      });
    }
  }, [inView]);

  return (
    <motion.div className="header" id="header" ref={ref} animate={animation}>
      <h1 className="blue">IHR KOMPETENTER PARTNER FÜR DEN DENTALBEDARF</h1>
      <p>
        <span className="blue">Service ist unsere Leidenschaft.</span> Damit Sie
        mehr Zeit haben für Ihre alltägliche Arbeit, stehen wir Ihnen bei allen
        Fragen rund um die Materialwirtschaft mit Rat und Tat zur Seite. Ganz
        gleich, ob Sie einfach nur einen zuverlässigen Partner suchen, ob es mal
        besonders eilig ist oder ob Sie sich gar für neue Instrumente
        interessieren: Wir sind für Sie da.
      </p>
    </motion.div>
  );
}

export default Header;
