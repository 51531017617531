import "./Downloads.css";
import { MdDesktopMac } from "react-icons/md";
import { HiDownload } from "react-icons/hi";
import prodkatalog from "../../images/DT-Katalog.png";
import eaglekatalog from "../../images/American Eagle_2019.png";
import lmdental from "../../images/LM Katalog 2020.png";
import zirc from "../../images/Zirc.png";
import KatalogFile from "../../files/120221_DT_Katalog (3).pdf";
import AmericanFile from "../../files/American Eagle_2019.pdf";
import ZircFile from "../../files/Zirc_2020.pdf";
import LmFile from "../../files/LM Katalog 2020.pdf";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

function Downloads() {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        transition: {
          duration: 0.8,
        },
      });
    }
    if (!inView) {
      animation.start({
        opacity: 0,
      });
    }
  }, [inView]);
  return (
    <motion.div ref={ref} animate={animation} className="downloads">
      <div className="downloads-header blue">
        <h2>DOWNLOADS</h2>
      </div>
      <div className="downloads-icon blue">
        <HiDownload className="download-down" />
      </div>
      <div className="downloads-imgs">
        <div className="downloads-wrapper">
          <div className="downloads-img">
            <img
              src={prodkatalog}
              alt="katalog"
              className="downloads-katalog"
            />
            <div className="downloads-icons">
              <a href={KatalogFile} target="_blank" rel="noreferrer">
                <MdDesktopMac className="downloads-icons blue" />
              </a>
              <a href={KatalogFile} download>
                <HiDownload className="downloads-icons blue" />
              </a>
            </div>
          </div>
          <div className="downloads-img mt-1">
            <img
              src={eaglekatalog}
              alt="katalog"
              className="downloads-katalog"
            />
            <div className="downloads-icons">
              <a href={AmericanFile} target="_blank" rel="noreferrer">
                <MdDesktopMac className="downloads-icons blue" />
              </a>
              <a href={AmericanFile} download>
                <HiDownload className="downloads-icons blue" />
              </a>
            </div>
          </div>
          <div className="downloads-img mt-2">
            <img
              src={lmdental}
              alt="katalog"
              className="downloads-katalog-lm"
            />
            <div className="downloads-icons">
              <a href={LmFile} target="_blank" rel="noreferrer">
                <MdDesktopMac className="downloads-icons blue" />
              </a>
              <a href={LmFile} download>
                <HiDownload className="downloads-icons blue" />
              </a>
            </div>
          </div>
          <div className="downloads-img">
            <img src={zirc} alt="katalog" className="downloads-katalog" />
            <div className="downloads-icons">
              <a href={ZircFile} target="_blank" rel="noreferrer">
                <MdDesktopMac className="downloads-icons blue" />
              </a>
              <a href={ZircFile} download>
                <HiDownload className="downloads-icons blue" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Downloads;
