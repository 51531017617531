import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

const CircularProgress = ({
  size,
  strokeWidth,
  percentage,
  color,
  end,
  decimals,
  suffix,
}) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        fill="none"
        stroke="#395693"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        fill="none"
        stroke={color}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={[dash, circumference - dash]}
        strokeLinecap="round"
        style={{ transition: "all 4.2s ease-out" }}
      />
      <text
        fill="#fff"
        fontSize="64px"
        fontWeight={500}
        x="50%"
        y="50%"
        dy="20px"
        textAnchor="middle"
      >
        <CountUp
          start={1}
          end={end}
          duration={4.2}
          useEasing={false}
          delay={0}
          decimals={decimals}
          suffix={suffix}
        >
          {({ countUpRef }) => <tspan ref={countUpRef} />}
        </CountUp>
      </text>
    </svg>
  );
};

export default CircularProgress;
