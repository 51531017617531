import { request, gql } from "graphql-request";

const graphqlAPI =
  "https://api-eu-central-1.hygraph.com/v2/cl7gfy6ah800801uh1gpe6mp4/master";

export const getData = async () => {
  const query = gql`
    query MyQuery {
      veranstaltungensConnection {
        edges {
          node {
            datum
            title
            fotos {
              url
            }
            frontFoto {
              url
            }
          }
        }
      }
    }
  `;

  const result = await request(graphqlAPI, query);

  return result.veranstaltungensConnection.edges;
};

export const getAktion = async () => {
  const newQuery = gql`
    query MyQuery {
      aktionensConnection {
        edges {
          node {
            flyer {
              url
            }
          }
        }
      }
    }
  `;

  const result = await request(graphqlAPI, newQuery);

  return result.aktionensConnection.edges;
};
