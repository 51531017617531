import "./Footer.css";
import { Link } from "react-router-dom";
import MailchimpNewsletter from "./MailchimpNewsletter/MailchimpNewsletter";
import Agbs from "../../files/agb-dt dental service.pdf";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="footer-kontakt">
          <h5>KONTAKT</h5>
          <p className="mt">DT Dental Service GmbH</p>
          <p>Neue Siedlung 34</p>
          <p>29553 Bienenbüttel/OT Rieste </p>
          <p>Telefon: 05823 46730 00</p>
          <a href="https://www.dt-dentalservice.de">www.dt-dentalservice.de</a>
        </div>
        <div className="footer-links">
          <h5>QUICKLINKS</h5>

          <ul>
            <li>
              <a href="#produkte" className="footer-link mt ">
                Produkte
              </a>
            </li>
            <li>
              <a href="#team" className="footer-link">
                Team
              </a>
            </li>
            <li>
              <a href="#downloads" className="footer-link">
                Downloads
              </a>
            </li>
            <li>
              <a href="#aktionen" className="footer-link">
                Aktionen
              </a>
            </li>
            <li>
            <a
                href="https://webshop.dt-dentalservice.de/"
              >
                Shop <span className="menu-icon"></span>
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Jobs
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-newsletter">
          <div className="footer-header-news">
            <h5>
              JETZT NEWSLETTER ABONNIEREN UND EINEN <br className="show"></br>{" "}
              5€ GUTSCHEIN ERHALTEN!
            </h5>
            <div className="footer-gutschein">
              <p className="bigger">5 €</p>
              <p>Gutschein sichern!</p>
            </div>
          </div>
          <div className="footer-form-news">
            <MailchimpNewsletter />
          </div>
        </div>
      </div>
      <div className="stopa">
        <div className="stopa-wrapper">
          <div className="stopa-links">
            <Link to="/impressum">Impressum</Link>
            <p>|</p>
            <Link to="/datenschutz">Datenschutz</Link>
            <p>|</p>
            <a
              href={Agbs}
              download="agb-dt dental service.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              AGB
            </a>
          </div>
          <div className="stopa-copy">
            <p>© {year} DT Dental Service - Alle Rechte vorbehalten</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
