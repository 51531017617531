import "./DtShop.css";
import { FiShoppingCart } from "react-icons/fi";

function DtShop() {
  const shopLink =
    "https://webshop.dt-dentalservice.de/";
  return (
    <div className="dt-shop" id="shop">
      <a href={shopLink}>
        <div className="dt-shop-wrapper dt-shop-over">
          <div className="dt-shop-icon">
            <FiShoppingCart />
          </div>
          <div className="dt-shop-header">
            <h3>DT-WEBSHOP</h3>
            <h4>
              FINDEN SIE ALLE PRODUKTE IN UNSEREM SHOP UND BESTELLEN SIE GANZ
              BEQUEM ONLINE
            </h4>
          </div>
        </div>
      </a>
    </div>
  );
}

export default DtShop;
